.home {
    .header-section {
        background-image: url('./images/header-bg.svg');   
        padding-top: 6%;     
        .header-partner {
            .img-partner {
                width: 80px;
                height: 100%;
                max-width: auto;
            }
        }
    }

    .provide-section {
        position: relative;
        .provide-content {
            position: absolute;
            top: 30%;
            right: 10%;
        }
        .section-2b{
            position: absolute;
            left: 40%;
            top: 30%;
        }
    }

    .service-section {
        .service-wrapper {
            position: absolute;
            top: 15%;
            width: 100%;
            height: 100%;
        }
        .service-content {
            position: relative;
            width: 100%;
            height: 100%;
        }
        .service-desc {
            position: absolute;
            top: 30%;
            padding: 0 24% 0 8%;
        }
    }

    .client-section {
        position: relative;
        .section-4a {
            position: absolute;
            width: 140px;
            top: 10%;
            left: 10%;
            
        }
        .section-4b {
            border: 1px solid $grey-300;
            border-radius: 50%;
            -webkit-box-shadow: 10px 10px 13px -2px rgba(232,232,232,1);
            -moz-box-shadow: 10px 10px 13px -2px rgba(232,232,232,1);
            box-shadow: 10px 10px 13px -2px rgba(232,232,232,1);
        }
        .client-caption {
            position: absolute;
            top: 20%;
            padding: 20%;
            left: 5%;
        }
    }

    //floating animation
    .float1 {
        left: 15%;
        top: 15%;
    }

    .float2 {
        right: 15%;
        top: 25%;
    }

    .float3 {
        left: 17%;
        top: 55%;
    }

    .float4 {
        right: 3%;
        top: 60%;
    }

    .float5 {
        left: 45%;
        top: 0;
    }

    .float6 {
        left: 35%;
        top: 0;
    }

    .float7 {
        left: 5%;
        bottom: 0;
    }

    .float8 {
        left: 45%;
        top: 15%;
    }

    .float9 {
        right: 5%;
        bottom: 0;
    }
}