.client {
     .header-section {
        background-image: url('./images/header-bg.svg');
        padding: 8% 0 0;
    }
    .section-clients {
        position: relative;
    }
    
    //floating animation
    .float1 {
        left: 5%;
        bottom: 9%;
    }
    
    .float2 {
        right: 10%;
        bottom: 15%;
    }
    
    .float3 {
        width: 70px;
        position: absolute;
        z-index: 9;
        right: 27%;
        top: 0;
    
    }
    
    .float4 {
        left: 10%;
        bottom: 10%;
    }
    
    
    .float5 {
        right: 27%;
        top: 0;
    
    }
    
    .float6 {
        left: 10%;
        bottom: 10%;
    }
    
    .float-particle1 {
        position: absolute;
        right: -10%;
        top: -10%;
        width: 30%;
    }
    
    .float-particle2 {
        position: absolute;
        left: -10%;
        bottom: -10%;
        width: 30%;
    }
}
