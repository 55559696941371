.about {
    .timeline {
        list-style: none;
        padding: 20px 0 20px;
        position: relative;
    }
    .timeline:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 15px;
        background-color: #fff;
        left: 50%;
        margin-left: -1.5px;
        border-radius: 32px;
        -webkit-box-shadow: 6px 0px 22px -6px rgba(186,186,186,1);
        -moz-box-shadow: 6px 0px 22px -6px rgba(186,186,186,1);
        box-shadow: 6px 0px 22px -6px rgba(186,186,186,1);
    }
    .timeline > li {
        margin-bottom: 20px;
        position: relative;
        display: flow-root;
    }
    
    .timeline > li.timeline-inverted > .timeline-panel:before{
        border-bottom: 16px solid transparent;
        border-right: 16px solid #fff;
        content: "";
        display: inline-block;
        left: -15px;
        position: absolute;
        top: 22px;
        border-top: 20px solid transparent;
    }
    .timeline > li.timeline-not-inverted > .timeline-panel:before {
        border-bottom: 16px solid transparent;
        border-left: 16px solid #fff;
        content: "";
        display: inline-block;
        right: -15px;
        position: absolute;
        top: 22px;
        border-top: 20px solid transparent;
    }
    .timeline > li > .timeline-badge {
        color: #fff;
        width: 40px;
        height: 40px;
        line-height: 50px;
        font-size: 1.4em;
        text-align: center;
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -12px;
        z-index: 100;
        border-radius: 50%;
        border: 4px solid $orange-light;
    }
    .timeline > li > .timeline-panel {
        width: 46%;
        float: left;
        position: relative;
        -webkit-box-shadow: 6px 0px 22px -6px rgba(186,186,186,1);
        -moz-box-shadow: 6px 0px 22px -6px rgba(186,186,186,1);
        box-shadow: 6px 0px 22px -6px rgba(186,186,186,1);
        border-radius: 10px;
        padding: 20px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
        float: right;
    }
    .timeline-inverted {
        display: table;
    }
    .section-31 {
        position: absolute;
        z-index: -1;
        width: 40%;
        top: 25%;
        left: 0;
    }
    .section-34 {
        position: absolute;
        z-index: -1;
        width: 40%;
        bottom: 0;
        right: 0;
    }
    .history-section {
        position: relative;
    }

    //floating animation
    .float1 {
        left: 8%;
        top: 15%;
    }

    .float2 {
        left: 50%;
        top: 0;
    }

    .float3 {
        right: 25%;
        bottom: 10%;
    }

    .float4 {
        right: 25%;
        top: 30%;
    }

    .float5 {
        left: 30%;
        top: 5%;
    }

    .float6 {
        bottom: 20%;
        left: 15%;
    }

    .float7 {
        z-index: -1;
        right: 8%;
        bottom: 0;
    }

    .float8 {
        z-index: -1;
        top: 25%;
        left: 20%;
    }

    .float9 {
        z-index: -1;
        top: 35%;
        left: 35%;
    }

    .float10 {
        z-index: -1;
        right: -5%;
        bottom: 15%;
    }

    .float11 {   
        z-index: -1;
        bottom: 0;
        right: 36%;
    }
}