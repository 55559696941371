.mobile-menu-wrapper {
  position: fixed;
  left: 0;
  top: 93px;
  height: 100%;
  width: 100%;
  background: white;
  padding: 16px;
  z-index: 140;
}

.mobile-menu-list {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.menu-active {
    color: $orange-dark !important;
}
