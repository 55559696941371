.navbar {
    padding: 1rem !important;
    -webkit-box-shadow: 0px 10px 21px -15px rgba(176,169,176,1);
    -moz-box-shadow: 0px 10px 21px -15px rgba(176,169,176,1);
    box-shadow: 0px 10px 21px -15px rgba(176,169,176,1);
    background: #ffffff;
    border-bottom: 1px solid $grey-200;
}
.navbar-collapse {
    flex-direction: row-reverse;
}
.navbar-link-active {
    color: $orange-dark !important;
}