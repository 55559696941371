.contact {
    .float1 {
        right: 25%;
        top: 15%;
    }
    .float2 {
        left: 15px;
        top: 0;
    }
    .float3 {
        left: 15%;
        bottom: 30%;
    }
    .float4 {
        right: 15%;
        bottom: 40%;
    }
    .float5 {
        bottom: 0;
        left: 15%;
    }
}