.development {
    .header-section {
        background-image: url('./images/header-bg.svg');
        padding: 5% 0 5% 0;
    }
    .float1 {
        right: 5%;
        top: 5%;
    }
    
    .float2 {
        left: 15px;
        bottom: 30%;
    }

    .float3 {
        right: 10%;
        top: 5%;
    }
    
    .float4 {
        right: 5%;
        top: 20%;
    }
}