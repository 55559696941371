$blue-dark: #3077e1;
$blue-light: #0099ff;
$orange-dark: #f97a44;
$orange-light: #ff9400;
$grey-100: #f8f9fa !default;
$grey-200: #e9ecef !default;
$grey-300: #dee2e6 !default;
$grey-400: #ced4da !default;
$grey-500: #adb5bd !default;
$grey-600: #6c757d !default;
$grey-700: #495057 !default;
$grey-800: #343a40 !default;
$grey-900: #212529 !default;
$black: #000 !default;
$dimensions: 20, 30, 40, 50, 60, 70, 80, 100;
$spaces: 5, 10, 15, 20, 30, 40, 50;

$borders: (
  top: top,
  bottom: bottom,
  left: left,
  right: right,
);

$colors: () !default;
$colors: map-merge(
  (
    "blue-dark": $blue-dark,
    "blue-light": $blue-light,
    "orange-dark": $orange-dark,
    "orange-light": $orange-light,
    "grey-100": $grey-100,
    "grey-200": $grey-200,
    "grey-300": $grey-300,
    "grey-400": $grey-400,
    "grey-500": $grey-500,
    "grey-600": $grey-600,
    "grey-700": $grey-700,
    "grey-800": $grey-800,
    "grey-900": $grey-900,
    "black": $black,
    "transparent": transparent,
  ),
  $colors
);

//color
@each $name, $value in $colors {
  .text-#{$name} {
    color: #{$value};
  }
  .bg-#{$name} {
    background: #{$value} !important;
  }
}

//border
@each $name, $border in $borders {
  .border-#{$name} {
    border-#{$name}: 1px solid $grey-300 !important;
  }
}

//dimension
@each $d in $dimensions {
  .d-width-#{$d} {
    width: if($d == "auto", $d, $d * 1px) !important;
  }
}

@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,600,600i,700,800&display=swap");
@import "./components/Navbar/Navbar.scss";
@import "./components/MobileMenu/MobileMenu.scss";
@import "./components/Footer/Footer.scss";
@import "./components/NewsCard/NewsCard.scss";
@import "./components/ScrollTop/ScrollTop.scss";
@import "./components/GetStarted/GetStarted.scss";

@import "./containers/Home/Home.scss";
@import "./containers/About/About.scss";
@import "./containers/School/School.scss";
@import "./containers/Development/Development.scss";
@import "./containers/News/News.scss";
@import "./containers/Client/Client.scss";
@import "./containers/Contact/Contact.scss";
@import "./mobile.scss";

body {
  font-family: "Quicksand", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

a {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.img-placeholder {
  width: 100%;
  height: auto;
  background-color: #ff9400;
}

//font
.font-body {
  font-size: 16px;
  letter-spacing: 1px;
}

.font-small {
  font-size: 13px;
  letter-spacing: 1px;
}

.font-weight-bold {
  font-weight: bold;
}

.font-uppercase {
  text-transform: uppercase;
}

.no-margin {
  margin: 0 !important;
}

.header-section {
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin: 0 auto;
}

.separator {
  border-bottom: 1px solid $grey-400;
}

.form-control {
  border: 0;
  -webkit-box-shadow: 8px 6px 8px 2px $grey-300;
  -moz-box-shadow: 8px 6px 8px 2px $grey-300;
  box-shadow: 8px 6px 8px 2px $grey-300;
  padding: 8px 16px 16px;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey-400;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $grey-400;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $grey-400;
  }
}

// generate buttons from colors
@each $name, $value in $colors {
  .btn-#{$name} {
    background: #{$value};
    color: color-yiq($value);
    border-color: if($value == #ffffff, #ccc, transparent);
    padding: 0 32px;
    border-radius: 32px;
    font-size: 14px;
    line-height: 28px;
    -webkit-box-shadow: 10px 10px 10px -10px rgb(61, 61, 61);
    -moz-box-shadow: 10px 10px 10px -10px rgb(61, 61, 61);
    box-shadow: 10px 10px 10px -10px rgb(61, 61, 61);
    margin: auto;
    &:hover,
    &:focus,
    &:visited,
    &:active {
      background: darken($value, 2%) !important;
      color: color-yiq(darken($value, 2%));
      border-color: if($value == #ffffff, #ccc, transparent);
    }

    &:disabled,
    &.disabled {
      background: #{$value};
      color: color-yiq($value);
      border-color: if($value == #ffffff, #ccc, transparent);
    }
  }
  .btn-outline-#{$name} {
    background: transparent;
    color: $value;
    border-color: #{$value};
    border-style: solid;
    padding: 0 32px;
    line-height: 26px;
    border-radius: 32px;
    font-size: 14px;
    -webkit-box-shadow: 10px 10px 21px -7px rgba(196, 196, 196, 1);
    -moz-box-shadow: 10px 10px 21px -7px rgba(196, 196, 196, 1);
    box-shadow: 10px 10px 21px -7px rgba(196, 196, 196, 1);
    margin: auto;
    &:hover,
    &:focus,
    &:visited,
    &:active {
      border-color: darken($value, 7.5%) !important;
      color: darken($value, 10%);
    }

    &:disabled,
    &.disabled {
      background: transparent;
      color: darken($value, 10%);
      border-color: #{$value};
    }
  }
}

.btn-select {
  width: 100%;
  -webkit-box-shadow: 8px 8px 16px -5px rgba(204, 204, 204, 1);
  -moz-box-shadow: 8px 8px 16px -5px rgba(204, 204, 204, 1);
  box-shadow: 8px 8px 16px -5px rgba(204, 204, 204, 1);
  background: #fff;
  border: 0;
  padding: 16px;
  cursor: pointer;
  border-radius: 5px;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.float {
  animation: float 6s ease-in-out infinite;
  border-radius: 50%;
}

.absolute {
  position: absolute;
  z-index: 9;
}

.set-backward {
  z-index: -1 !important;
}

.set-forward {
  z-index: 9 !important;
}

.pulse {
  border-radius: 50%;
  cursor: pointer;
}

.pulse-blue {
  animation: pulse-blue 2s infinite;
}

.pulse-orange {
  animation: pulse-orange 2s infinite;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 #c6ddff;
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px transparent;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 transparent;
  }
}

@keyframes pulse-blue {
  0% {
    -moz-box-shadow: 0 0 0 0 #c6ddff;
    box-shadow: 0 0 0 0 #c6ddff;
  }
  70% {
    -moz-box-shadow: 0 0 0 15px transparent;
    box-shadow: 0 0 0 15px transparent;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }
}

@-webkit-keyframes pulse-orange {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse-orange {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

//margin in %
@each $space in $spaces {
  .space-#{$space} {
    margin: if($space == "auto", $space, $space * 1%) !important;
  }
  .space-#{$space}-top {
    margin-top: if($space == "auto", $space, $space * 1%) !important;
  }
  .space-#{$space}-bottom {
    margin-bottom: if($space == "auto", $space, $space * 1%) !important;
  }
  .space-#{$space}-left {
    margin-left: if($space == "auto", $space, $space * 1%) !important;
  }
  .space-#{$space}-right {
    margin-right: if($space == "auto", $space, $space * 1%) !important;
  }
}
