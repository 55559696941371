.started-section {
    .float1 {
        left: 15%;
        top: 5%;
    }

    .float2 {
        right: 5%;
        bottom: 0;
    }
}