.scroll-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999;
    transform: scale(1);
    cursor: pointer;
    .button-top {
        width: 70px;
        height: 70px;
    }
}