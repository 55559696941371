.news {
    .header-section {
        background-image: url('./images/header-bg.svg');
        padding: 10% 0 5% 0;
    }
    .float1 {
        left: 15px;
        bottom: 40%;
    }
    
    .float2 {
        right: 0;
        bottom: 20%;
    }
}