$mobile-dimensions: () !default;
$mobile-dimensions: map-merge(
  (
    '80': 60,
    '70': 50,
    '60': 40,
    '50': 30,
    '40': 20,
    '30': 10
  ),
  $mobile-dimensions
);

@media only screen and (max-width: 786px) {
    .header-section {
        margin: 0 auto !important;
        background-size: cover !important;
    }

    .font-body {
        font-size: 11px !important;
    }

    .font-small {
        font-size: 11px !important;
    }

    h2 {
        font-size: 24px;
    }

    .navbar {
        padding: 5px 15px !important;
    }

    .mobile-menu-wrapper {
        top: 78px;
    }

    //Home
    .home {
        .header-section {
            margin: 0;
            padding: 24px 0;
            .section-1 {
                position: relative;
                bottom: 0;
                width: 100%;
            }
            .header-partner {
                .img-partner {
                    width: 60px;
                }
            }
            .header-intro {
                margin: 0;
            }
        }

        .service-section {
            .service-wrapper {
                position: relative;
                left: 0;
                text-align: center;
                padding: 9%;
            }
            .service-desc {
                top: 40%;
            }
        }

        .client-section {
            .client-content {
                padding: 0;
                .client-caption {
                    position: absolute;
                    top: 23%;
                    padding: 15%;
                    left: 18%;
                }

                .section-4a {
                    width: 100px;
                    top: 8%;
                }

                .client-chosen {
                    margin: -18px;
                }
            }
        }
    }

    //About
    .about {
        .history-section {
            margin-bottom: 30% !important;
        }
        .timeline {
            padding: 70px 0 0;
            margin-bottom: 50px;
        }
        .timeline>li.timeline-inverted>.timeline-panel {
            width: 90%;
        }
        .timeline > li > .timeline-panel {
            float: right;
            width: 90%;
        }
        .timeline:before {
            left: 0;
        }
        .timeline > li > .timeline-badge {
            left: 0;
        }
        .timeline > li.timeline-not-inverted > .timeline-panel:before {
            border-right: 16px solid #fff;
            left: -15px;
            right: auto;
            border-left: 0;;
        }
    }

    //dimensions
    @each $d, $value in $mobile-dimensions {
        .d-width-#{$d} {
            width: if($value == 'auto', $value, $value * 1px) !important;;
        }
    }   
}